import React, { useEffect, useState } from "react";
import type { components as ScoreSaber } from "./types/scoresaber";

import logo from "./logo.svg";
import "./App.css";
import ms from "ms";

export interface Play {
  id: string;
  info: ScoreSaber["schemas"]["LeaderboardInfo"];
  score: number;
  timeSet: Date;
  pp: number;
  ppWeighted: number;
}
interface GameState {
  timeStarted: number;
  timeSpent: number;

  pp: number;
  plays: Play[];
}

interface GameStateMessage {
  data: "state";
  state: GameState;
}

const socket = new WebSocket("ws://localhost:38957");
const isStateMessage = (t: any): t is GameStateMessage =>
  "data" in t && t.data === "state";
function App() {
  const [state, setState] = useState<GameState>();
  useEffect(() => {
    socket.onmessage = (data) => {
      const msg = JSON.parse(data.data);
      if (!isStateMessage(msg)) return;

      setState(msg.state);
    };
  }, [state]);

  return (
    <>
      {state ? (
        <div className="flex flex-col rounded-xl  bg-gray-900 text-white overflow-hidden text-xl max-h-[100vh]">
          <div className="p-4 px-6 bg-white/5">
            <div>
              <strong>PP:</strong> {state.pp.toFixed(2)}
            </div>
          </div>
          <div className="flex flex-col gap-4 p-4">
            {state.plays.map((play) => (
              <div key={play.id} className="flex gap-4 h-16 items-center">
                <img
                  src={`https://cdn.scoresaber.com/covers/${play.info.songHash}.png`}
                  className="aspect-square w-16"
                  alt=""
                />
                <div>
                  <strong>
                    {play.info.songAuthorName} - {play.info.songName}
                  </strong>
                  <div className="opacity-50">
                    {play.pp.toFixed(2)}pp ({play.ppWeighted.toFixed(2)}{" "}
                    weighted)
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        "no connection"
      )}
    </>
  );
}

export default App;
